import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import JoinUs from '../../sections/joinUs'
import Products from '../../sections/gallery'
import Designers from '../../sections/gallery-designers'

export default ({ data }) => (
  <Layout headSize="small">
    <SEO title={`Underwoods Product Galleries`} />
    <Products data={data} title={`A selection from the Underwood's Estate Collection`}/>
    <Designers />
    <JoinUs />
  </Layout>
)

export const GalleryQuery = graphql`
  query GalleryQuery {
    allProductGalleryJson {
      edges{
        node {
          path
          title
          image
        }
      }
    }
  }
`
