import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { colors, md } from '../common'

export default ({ data }) => {
  const items = data.allProductGalleryJson.edges
  return(
    <div css={`padding:2rem;text-align:center;${md}{padding:1rem .5rem;}`}>
      <h1 css={`color:${colors.green};text-transform:uppercase;`}>Browse a Curated Selction of our fine products</h1>
      <Wrap>
        {items.map((item, index) => {
          const i = item.node
          return  <Link key={index} to={i.path} css={`${md}{width:100%;}`}>
                    <Card>
                      <div css={`margin:5px;`}>
                        <img src={i.image} alt={i.title} />
                      </div>
                      <p>{i.title}</p>
                    </Card>
                  </Link>
        })}
      </Wrap>
      <br />
      <br />
    </div>
  )
}

const Card = styled.div`
  max-width: 350px;
  margin: 1rem .5rem;
  text-align:center;
  align-self:top;
  border: .5px solid rgba(0,0,0,.2);
  background-color: ${colors.greyDarkest};
  color: white;
  text-transform:uppercase;

  img {
    width: 100%;
    min-width:340px;
    padding:.5px;
  }

  p {
    font-size:20px;
  }

  ${md} {
    align-self:center;
    margin:auto;
    margin-bottom: .75rem;
    img {
      min-width: 10px;
    }
  }
`

const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  margin: auto;

  ${md} {
    flex-direction:column;
  }
`
