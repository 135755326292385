import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql, Link } from 'gatsby'
import { colors, md } from '../common'

export default () => {
  return(
  <StaticQuery
    query={graphql`
      query DesignerGalleryQuery {
        allDesignersJson {
          edges {
            node {
              logo
              name
            }
          }
        }
      }
      `}
      render={ data => (
        <div>
          <div css={`background-color:${colors.greyLight};padding:2rem 2rem 4rem 2rem;${md}{padding: 1rem;}`}>
            <h1 css={`text-align:center;color:${colors.green};text-transform:uppercase;`}>Featured Designers</h1>
            <div css={`display:flex;justify-content:space-between;${md}{flex-direction:column;}`}>
              <A to={`/rolex`}><img src="https://s3.amazonaws.com/underwoods/_designers/rolex/logo.png" alt="Rolex" /></A>
              <A to={`/designer/benchmark`}><img src="https://s3.amazonaws.com/underwoods/_designers/benchmark/logo.png" alt="Benchmark" /></A>
              <A to={`/designer/robertocoin`}><img src="https://s3.amazonaws.com/underwoods/_designers/roberto-coin/logo.png" alt="Roberto Coin" /></A>
              <A to={`/designer/michele`}><img src="https://s3.amazonaws.com/underwoods/_designers/michele-watch/logo.png" alt="Michele" /></A>
            </div>
          </div>

          <h1 css={`text-align:center;color:${colors.green};text-transform:uppercase;margin:4rem;`}>Some of the Fine Designers at Underwood's</h1>
          <div css={`display:flex;flex-wrap:wrap;justify-content:center;padding:0 2rem;`}>
            {data.allDesignersJson.edges.map((designer, index) => {
              let link = ''
              switch (designer.node.name) {
                // case 'Rolex':
                //   link = 'rolex'
                //   break
                case 'Benchmark Wedding Bands':
                  link = 'benchmark'
                  break
                case 'Tudor':
                  link = 'tudor'
                  break
                case 'Forevermark':
                  link = 'forevermark'
                  break
                case 'Roberto Coin':
                  link = 'robertocoin'
                  break
                case 'Seiko':
                  link = 'seiko'
                  break
                default:
                  link = designer.node.name
                  break
              }
              return  <div key={index} css={`display:flex;justify-content:center;min-height:200px;cursor:pointer;`}>
                          <Link css={`padding:1rem;width:300px;display:flex;${md}{padding:0;}`} to={`/designer/${link}`}><img css={`width:90%;align-self:center;margin:auto;`} src={designer.node.logo} alt={designer.node.name} /></Link>
                      </div>
            })}
          </div>
        </div>
      )}
  />
)}

const A = styled(Link)`
  padding:1rem;
  width:300px;
  display:flex;
  background-color:white;
  border: .5px solid dimgrey;
  margin:1rem;

  img {
    width:90%;
    align-self:center;
    margin:auto;
    padding:1rem;
  }

  ${md} {
    padding:0;
    width:100%;
    margin:.5rem 0;
  }
`
